//SVG
import svg_icon_flag from './flag.svg';
import svg_icon_arrow_right from './arrow-right.svg';
import svg_icon_adjustments_horizontal from './adjustments-horizontal.svg';
import svg_icon_adjustments_horizontal_white from './adjustments-horizontal-white.svg';
import svg_icon_box from './box.svg';
import svg_icon_box_white from './box-white.svg';
import svg_icon_dollar_sign from './dollar-sign.svg';
import svg_icon_dollar_sign_white from './dollar-sign-white.svg';
import svg_icon_megaphone from './megaphone.svg';
import svg_icon_megaphone_white from './megaphone-white.svg';
import svg_icon_people from './people.svg';
import svg_icon_people_white from './people-white.svg';
import svg_icon_check_circle from './check-circle.svg';
import svg_icon_lock_closed from './lock-closed.svg';
import svg_icon_mini_check from './mini_check.svg';

export const assetsIcon = {
  svgs:{
    svg_icon_flag,
    svg_icon_arrow_right,
    svg_icon_adjustments_horizontal,
    svg_icon_adjustments_horizontal_white,
    svg_icon_box,
    svg_icon_box_white,
    svg_icon_dollar_sign,
    svg_icon_dollar_sign_white,
    svg_icon_megaphone,
    svg_icon_megaphone_white,
    svg_icon_people,
    svg_icon_people_white,
    svg_icon_check_circle,
    svg_icon_lock_closed,
    svg_icon_mini_check
  }
}