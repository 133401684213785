import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

function PesquisaPerfilQuestao({
	enunciado,
	alternativas,
	alternativaSelecionada,
	onChange,
}) {
	return (
		<Box>
			<Typography
				align="justify"
				variant="h1"
				sx={{
					color: "#374151",
					fontSize: { xs: "15px", sm: "24px" },
					fontWeight: 700,
					fontStyle: "normal",
					fontFamily: "Inter",
				}}
			>
				{enunciado}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<RadioGroup
					name="alternativas"
					value={alternativaSelecionada}
					onChange={onChange}
				>
					{alternativas.map((alternativa, index) => (
						<FormControlLabel
							sx={{ mt: "8px" }}
							key={`alternativa-${crypto.randomUUID()}`}
							control={<Radio value={index} />}
							label={
								<Typography
									align="justify"
									sx={{
										color: "#374151",
										fontSize: { xs: "12px", sm: "16px" },
										fontWeight: 400,
										lineHeight: "150%",
										fontStyle: "normal",
										fontFamily: "Inter",
									}}
								>
									{alternativa.label}
								</Typography>
							}
							value={alternativa.value}
						/>
					))}
				</RadioGroup>
			</Box>
		</Box>
	);
}

export default PesquisaPerfilQuestao;
