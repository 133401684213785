//SVG
// import svg_logo_blue from './logo_blue.svg';
// import svg_logo_blue_clear from './logo_blue_clear.svg';
// import svg_logo_white_clear from './logo_white_clear.svg';

// NOVOS LOGOS
import svg_logo_blue from './novo_logo/svg/logo_nome.svg';
import svg_logo_blue_clear from './novo_logo/svg/logo.svg';
import svg_logo_white_clear from './novo_logo/svg/logo.svg';

import svg_processando_perfil from './tela_svg_processando_perfil.svg';
import svg_processando_diagnostico from './tela_svg_processando_diagnostico.svg';
import svg_resultado_diagnostico from './tela_svg_resultado_diagnostico.svg';
import svg_bk_trocar_senha from './bk_trocar_senha.svg';
import svg_bk_trocar_senha_sucesso from './bk_trocar_senha_sucesso.svg';

//Imagens
// const logo_blue = require('./logo_blue.png');
// const logo_blue_clear = require('./logo_blue_clear.png');
const tela_img_login = require('./tela_img_login.png');
const tela_img_primeiros_passos = require('./tela_img_primeiros_passos.png');
const tela_img_perfil = require('./tela_img_perfil.png');
const bk_trocar_senha = require('./bk_trocar_senha.png');
const bk_trocar_senha_sucesso = require('./bk_trocar_senha_sucesso.png');
const tela_resultado_diagnostico = require('./tela_resultado_diagnostico.png');

// NOVOS LOGOS
//PNG
const logo_blue = require('./novo_logo/png/logo_nome_horizontal.png');
const logo_blue_clear = require('./novo_logo/png/logo.png');

export const assets = {
  imgs: {
    logo_blue,
    logo_blue_clear,
    tela_img_login,
    tela_img_primeiros_passos,
    tela_img_perfil,
    bk_trocar_senha,
    bk_trocar_senha_sucesso,
    tela_resultado_diagnostico
  },
  svgs:{
    svg_logo_blue,
    svg_logo_blue_clear,
    svg_logo_white_clear,
    svg_processando_perfil,
    svg_processando_diagnostico,
    svg_resultado_diagnostico,
    svg_bk_trocar_senha,
    svg_bk_trocar_senha_sucesso
  }
}