import React from "react";
import { ReactSVG } from "react-svg";
import { Box} from "@mui/material";

import { assets } from "../../assets/images_sys";

export function HeaderMain() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
				backgroundColor: "#FFFFFF",
			}}
		>
			<Box
				sx={{
					ml: 3,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box sx={{ mt: 1 }}>
					<ReactSVG 
						src={assets.svgs.svg_logo_blue}
						beforeInjection={(svg) => {
							svg.setAttribute('style', 'width: 220px')
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
}
