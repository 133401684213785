import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import PesquisaPerfilForm from "../../components/PesquisaPerfilForm";
import { apiOnboarding } from "../../services/apiInterface/onboarding";

export function OnboardingQuestionsClients() {
	const { state } = useLocation();

	const navigate = useNavigate();

	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleGenerateProfile = async (data) => {
		try {

			const result = {
				contExecutivo: 0,
				contEmpresarial: 0,
				contEspecialista: 0
			}


			for (const item of data) {
				if(item.classificacao === 'Executivo'){
					result.contExecutivo = result.contExecutivo + 1;
				}
				if(item.classificacao === 'Empresarial'){
					result.contEmpresarial = result.contEmpresarial + 1;
				}
				if(item.classificacao === 'Especialista'){
					result.contEspecialista = result.contEspecialista + 1;
				}
			}

			const dataFormated = {
				result: data,
				nome: state.nome,
				email: state.email,
				telefone: state.telefone,
			};

			const { ok, msg } = await apiOnboarding.create(dataFormated);
			if (ok) {
				navigate("/processandoResultado", {state: result});
			} else {
				console.log("Error msg:", msg);
			}
		} catch (error) {
			console.log("Error:", error.message);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const resp = await apiOnboarding.getAllActives();
				setQuestions(resp);
				setLoading(false);
			} catch (error) {
				alert("Error: " + error.message);
			}
		})();
	}, []);

	return (
		<>
			{!loading ? (
				<Box
					sx={{
						px: "20px",
						py: "30px",
						display: "flex",
						flex: 1,
						flexDirection: "column",
						justifyContent: {sm:'center'}
					}}
				>
					<PesquisaPerfilForm
						listaQuestoes={questions}
						submeterFormulario={handleGenerateProfile}
					/>
				</Box>
			) : (
				<Box sx={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
					<CircularProgress/>
				</Box>
			)}
		</>
	);
}
