export const Environment = {
  /**
   * Ambiente
   */
  // ENV: 'DEV', //DEV
  ENV: 'HML', //HML
  // ENV: 'PRD', //PRD
   /**
   * URL padrão para a API
   */
  // URL_API: "http://localhost:8000/api", //DEV
  URL_API: "https://gepaas.com.br/api", //HML
  // URL_API: "https://gloupi.com.br/api", //PRD
  /**
   * Dominio APP
   */
  // DOMAIN_APP: 'localhost', //DEV
  DOMAIN_APP: 'gepaas', //HML
  // DOMAIN_APP: 'gloupi', //PRD
  /**
   * Dominio APP COMPLETO -- EM PRODUCAO SERA gloupi.com.br
   */
  // DOMAIN_APP_FULL: 'localhost:8000' //DEV
  DOMAIN_APP_FULL: 'gepaas.com.br' //HML
  // DOMAIN_APP_FULL: 'gloupi.com.br' //PRD
}