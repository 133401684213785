import { Box } from "@mui/material";
import PesquisaPerfilQuestionario from "../PesquisaPerfilQuestionario";

function PesquisaPerfilForm({listaQuestoes, submeterFormulario}) {
	return (
		<Box sx={{ display: "flex" }}>
			<PesquisaPerfilQuestionario
				listaQuestoes={listaQuestoes}
				onFinish={submeterFormulario}
			/>
		</Box>
	);
}

export default PesquisaPerfilForm;
