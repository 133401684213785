import api from "../axios_config";

const getAllActives = async () => {
  const resp = await api.get(`/onboardingPerguntas`);
  
  let questionsFormated = [];
  for (const itemPergunta of resp.data.registers) {
    let objPer = {
      id: itemPergunta.id,
      enunciado: itemPergunta.pergunta,
      alternativa: undefined,
    }
    let alternativesFormated = [];
    for (const itemAlternativas of itemPergunta.onboarding_perguntas_alternativas) {
      let objAlt = {
        value: itemAlternativas.id,
        classificacao: itemAlternativas.classificacao,
        label: itemAlternativas.alternativa,
      }
      alternativesFormated.push(objAlt);
    }
    objPer.alternativas = alternativesFormated;
    questionsFormated.push(objPer);
  }
  
  return questionsFormated;
}

const create = async (result) => {
  const resp = await api.post(`/onboardingResultado`, result);
  return resp.data;
}

export const apiOnboarding = {
  getAllActives,
  create
}