import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function NotFound() {
	const [count, setCount] = useState(5);
	let timerId;

	const navigate = useNavigate();

	const startCountdown = () => {
    timerId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
  };

	useEffect(() => {
		startCountdown();

    const timer = setTimeout(() => {
      // Redirect to another screen after 5 seconds (5000 milliseconds)
      navigate('/');
    }, 5000);

    return () => {
			clearInterval(timerId);
      clearTimeout(timer);
    };
  },[]);

  return (
    <Box sx={{ display:'flex', width: "100vw", height:'100%'}}>
			<Typography sx={{m: '20px', mt:'16px',fontSize:'24px', fontWeight:700, lineHeight:'130%', color:'#374151', fontFamily:'Inter'}}>
				A página que está tentando acessar não existe! Dentro de {count} segundos você será redirecionado para a página inicial!
			</Typography>
		</Box>
  )
}