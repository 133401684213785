import { Route, Routes } from "react-router-dom";
import { NotFound, OnboardingLayout, OnboardingInitialClients, OnboardingQuestionsClients, OnboardingProcessingResultClients, OnboardingResultProfileClients  } from "../screens";

function RouteOnboarding() {
	return (
		<Routes>
			<Route path="/" element={<OnboardingLayout />}>
				<Route index element={<OnboardingInitialClients />}/>
				<Route path="questoes" element={<OnboardingQuestionsClients />}/>
				<Route path="processandoResultado" element={<OnboardingProcessingResultClients />}/>
				<Route path="resultado" element={<OnboardingResultProfileClients />}/>
				<Route path="*" element={<NotFound />}/>
			</Route>
		</Routes>
	);
}

export default RouteOnboarding;
