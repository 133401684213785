import React from "react";
import * as yup from "yup";
import { ReactSVG } from 'react-svg';
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { EmailOutlined } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Box, Button, TextField, Typography } from "@mui/material";
import InputMask from 'react-input-mask';

import { assetsIcon } from "../../assets/icons";
import { assets } from "../../assets/images_sys";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessageForm from "../../components/ErrorMessageForm";
import { utils } from "../../utils";

export function OnboardingInitialClients() {

  const navigate = useNavigate();

  const validation = yup.object({
		nome: yup.string().required("Para continuar, insira seu nome."),
		email: yup.string().email("Formato de e-mail inválido.").required("Para continuar, insira seu melhor email."),
		telefone: yup.string().required("Para continuar, insira seu telefone."),
	});

  const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(validation) });

  const handleBottom = (data) => {		
		data.telefone = utils.specialCharacterRemover(data.telefone);
    navigate('/questoes', {state: data});
  }

  return (
    <Box sx={{ display:'flex', width: "100vw", height:'100%'}}>
			<Box sx={{width:{xs:'100%',sm:'40%'}, display:'flex', justifyContent:{xs:'center',sm:'right'}, mt:{xs:'30px',sm:'150px'}}}>
				<Box sx={{width:{xs:'90%',sm:'437px'}, height:{xs:'80%',sm:'70%'}}}>
					<ReactSVG src={assetsIcon.svgs.svg_icon_flag}/>
					<Typography sx={{mt:'16px',fontSize:'24px', fontWeight:700, lineHeight:'130%', color:'#374151', fontFamily:'Inter'}}>
						Pronta para iniciar os seus primeiros passos na plataforma?
					</Typography>
					<Typography sx={{mt:'16px',fontSize:'20px', fontWeight:400, lineHeight:'150%', color:'#374151', fontFamily:'Inter'}}>
						Iniciaremos nosso processo de identificar o seu perfil na tomada de decisões relacionada ao seu negócio.
					</Typography>
					<Typography sx={{mt:'16px', fontSize:'16px', fontWeight:400, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
						Informe seus dados:
					</Typography>
					<TextField
						sx={{mt:'16px', width:'100%'}}
						variant="outlined"
						label="Digite seu nome"
						type={'text'}
						InputProps={{
							endAdornment: (
								<PersonIcon sx={{ mr: 1, color:'#9CA3AF' }} />
							),
						}}
						{...register("nome")}
					/>
					<ErrorMessageForm errorField={errors.nome} />
					<TextField
						sx={{mt:'16px', width:'100%'}}
						variant="outlined"
						label="Digite seu e-mail"
						type={'email'}
						InputProps={{
							endAdornment: (
								<EmailOutlined sx={{ mr: 1, color:'#9CA3AF' }} />
							),
						}}
						{...register("email")}
					/>
					<ErrorMessageForm errorField={errors.email} />
					<TextField
						sx={{mt:'16px', width:'100%'}}
						variant="outlined"
						label="Digite seu telefone com DDD"
						type="text"
						InputProps={{
							endAdornment: (
								<SmartphoneIcon sx={{ mr: 1, color:'#9CA3AF' }} />
							),
							inputComponent: InputMask,
							inputProps:{
								mask: '(99) 9 9999-9999'
							}
						}}
						{...register("telefone")}
					/>
					<ErrorMessageForm errorField={errors.telefone} />
					<Button
						variant="contained"
						sx={{ mt:'32px',width: "260px", fontSize:'16px', textTransform:'none', backgroundColor:'#3466B2'}}
						endIcon={<ArrowForward />}
						onClick={handleSubmit(handleBottom)}
					>
						<Typography sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
							Vamos, estou pronto
						</Typography>
					</Button>
				</Box>
			</Box>
			<Box sx={{width:'60%', display:{xs:'none',lg:'flex'}, alignItems:'center', justifyContent:'right'}}>
				<Box sx={{height:600, display:'flex', alignItems:'center', justifyContent:'right'}}>
					<img src={assets.imgs.tela_img_primeiros_passos} style={{width: '90%', height: 'auto'}} alt="Imagem Go Get Em"/>
				</Box>
			</Box>
		</Box>
  )
}