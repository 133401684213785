import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel,
	VictoryLegend,
	VictoryStack,
} from "victory";

function ProfileChart({
	dataset,
	duracaoAnimacaoSegundos = 0,
	escalaCores = ["#092349", "#3366B1", "#A7CAFE"],
}) {
	const valoresEmPorcentagem = (dataset = []) => {
		const totals = dataset[0].map((_, i) => {
			return dataset.reduce((soma, atual) => {
				return soma + atual[i].y;
			}, 0);
		});
		const novoDataset = dataset.map((data) => {
			return data.map((datum, i) => {
				return { x: datum.x, y: (datum.y / totals[i]) * 100 };
			});
		});
		return novoDataset;
	};

	const getNomesTiposPerfil = () => {
		const nomeTiposPerfil = [];
		for (const tipoPerfil in dataset) {
			nomeTiposPerfil.push(tipoPerfil);
		}
		return nomeTiposPerfil;
	};

	const getNomesPerfis = () => {
		const nomePerfis = [];
		let nomePerfisUnicos = [];

		// Pega o nome dos perfis em cada tipo de perfil
		for (const tipoPerfil in dataset) {
			const perfis = dataset[tipoPerfil];
			for (const perfil in perfis) nomePerfis.push(perfil);
		}

		// Eliminima os nomes de perfis duplicados/multiplicados
		nomePerfisUnicos = nomePerfis.filter((perfil, index) => {
			return nomePerfis.indexOf(perfil) === index;
		});

		return nomePerfisUnicos;
	};

	const getDataset = () => {
		let novoDataset = [];

		for (const tipoPerfil in dataset) {
			const novoTipoPerfil = [];
			const perfis = dataset[tipoPerfil];

			for (const perfil in perfis) {
				let graficoInfo = {
					x: perfil,
					y: perfis[perfil],
				};
				novoTipoPerfil.push(graficoInfo);
			}

			novoDataset.push(novoTipoPerfil);
		}

		novoDataset = valoresEmPorcentagem(novoDataset);

		return novoDataset;
	};

	return (
		<VictoryChart
			horizontal
			domainPadding={{ x: 200 }}
			padding={{
				left: 100,
				right: 75,
				top: 0,
				bottom: 0,
			}}
			style={{
				parent: {
					// width:{xs:'142%',lg:'500px'},
					borderColor: "#eee",
					borderWidth: 3,
					borderStyle: "solid",
					backgroundColor: "#ffffff",
					borderRadius: "4px",
				},
			}}
		>
			<VictoryStack colorScale={escalaCores} xOffset={1.38}>
				{getDataset().map((data, index, array) => {
					return (
						<VictoryBar
							key={index}
							data={data}
							alignment="middle"
							barWidth={35}
							cornerRadius={{
								bottom: index === 0 ? 3 : 0,
								top: index === array.length - 1 ? 3 : 0,
							}}
							animate={{
								onLoad: {
									duration: duracaoAnimacaoSegundos * 1000,
								},
							}}
							style={{ labels: { fill: "#FFFFFF" } }}
							labels={({ datum }) =>
								datum.x === "Seu Perfil" && datum.y > 0
									? `${Math.round(datum.y)}%`
									: ""
							}
							labelComponent={<VictoryLabel dx={-30} />}
						/>
					);
				})}
				<VictoryAxis
					orientation="left"
					tickFormat={getNomesPerfis()}
					axisComponent={<></>}
					tickLabelComponent={
						<VictoryLabel
							textAnchor={"end"}
							verticalAnchor={"middle"}
							dx={0}
							dy={-88}
							style={{
								fontSize: "14px",
								fontWeight: 500,
								lineHeight: "150%",
								color: "#374151",
								fontFamily: "Inter"
							}}
						/>
					}
				/>
			</VictoryStack>

			<VictoryLegend
				x={95}
				y={270}
				orientation="horizontal"
				gutter={30}
				data={getNomesTiposPerfil().map((value, index) => {
					return {
						name: value,
						symbol: {
							fill: escalaCores[index],
						},
					};
				})}
				style={{
					labels: {
						fontSize: 12,
						fontWeight: 500,
						lineHeight: "150%",
						color: "#374151",
						fontFamily: "Inter",
					},
				}}
			/>
		</VictoryChart>
	);
}
export default ProfileChart;
