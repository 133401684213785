import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { HeaderMain } from "../../components/HeaderMain";
import { ContainerMain } from "../../components/ContainerMain";

export function OnboardingLayout() {
  return (
    <ContainerMain>
      <HeaderMain
        height={'6%'}
        disableButton={true}
      />
      <Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
        <Outlet/>
      </Box>
    </ContainerMain>
  )
}