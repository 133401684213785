import axios from "axios";
import { Environment } from "../../environment"; 
import { responseInterceptor, errorInterceptor } from './interceptors';

const api = axios.create({
  baseURL: Environment.URL_API,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  (response)=> responseInterceptor(response),
  (error) => errorInterceptor(error)
);

export default api;