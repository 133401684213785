import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import { assets } from "../../assets/images_sys";
import ProfileChart from "./ProfileChart";

export function OnboardingResultProfileClients() {

  const {state} = useLocation();

  const navigate = useNavigate();

  const handleBottom = () => {
    navigate('/');
  }

  const [dadosGraficos, setDadosGraficos] = useState({
		Executivo: {
			"Seu Perfil": state?.contExecutivo, // Altera apenas aqui
			Especialista: 0,
			Empresarial: 1,
			Executivo: 5,
		},
		Empresarial: {
			"Seu Perfil": state?.contEmpresarial, // Altera apenas aqui
			Especialista: 2,
			Empresarial: 2,
			Executivo: 1,
		},
		Especialista: {
			"Seu Perfil": state?.contEspecialista, // Altera apenas aqui
			Especialista: 5,
			Empresarial: 3,
			Executivo: 1,
		},
	});
  
  return (
    <Box sx={{ display:'flex', width: "100%", height:'100%'}}>
      <Box sx={{width:'60%', display:{xs:'none',lg:'flex'}, alignItems:'center', justifyContent:'left'}}>
        <Box sx={{height:600, display:'flex', alignItems:'center', justifyContent:'left'}}>
          <img src={assets.imgs.tela_img_perfil} style={{width: '90%', height: 'auto'}} alt={"Logo Cheffinanceiro"}/>
        </Box>
      </Box>

      <Box sx={{width:{xs:'100%',lg:'40%'}, height: "100%", display:'flex', alignItems:'center', justifyContent:'left'}}>
        <Box sx={{width:{xs:'100%', lg:'437px'}, mx:{sm:0, xs:1}}}>
          <Typography sx={{fontSize:'24px', fontWeight:700, lineHeight:'130%', color:'#4B5563', fontFamily:'Inter'}}>
            Este é o seu perfil empresarial:
          </Typography>
          <Typography sx={{mt:'8px',fontSize:'16px', fontWeight:500, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
            As habilidades de que os empresários da área da Saúde e Estética precisam nos diferentes estágios da empresa:
          </Typography>

          <Box
            sx={{
              mt: "24px",
              ml: 0,
              width: "100%",
              backgroundColor: "transparent",
            }}
          >
            <ProfileChart
              dataset={dadosGraficos}
              duracaoAnimacaoSegundos={2}
              
            />
          </Box>

          {/* <Typography sx={{mt:'40px',fontSize:'16px', fontWeight:500, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
            Mostramos o seu perfil, agora analisaremos o seu negócio, está pronta?
          </Typography> */}
          <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:{xs:'center',lg:'left'}}}>
            <Button
              variant="contained"
              sx={{ mt:'32px',width: "260px", fontSize:'16px', textTransform:'none', backgroundColor:'#3466B2'}}
              // endIcon={<ReactSVG src={assetsIcon.svgs.svg_icon_arrow_right}/>}
              endIcon={<ArrowForward />}
              onClick={handleBottom}
            >
              <Typography sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
                Responder novamente
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}