import React from "react";
import './index.css';
import ReactDOM from "react-dom/client";
import RouteOnboarding from "./routes/RouteOnboarding";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<RouteOnboarding />
	</BrowserRouter>
);
