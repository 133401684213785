import { ArrowBack, ArrowForward, Circle } from "@mui/icons-material";
import {
	Button,
	Box,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useState } from "react";
import PesquisaPerfilQuestao from "../PesquisaPerfilQuestao";

function PesquisaPerfilQuestionario({ listaQuestoes, onFinish = undefined }) {
	const [questaoAtual, setQuestaoAtual] = useState(1);
	const [questoes, setQuestoes] = useState(listaQuestoes);

	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	const irParaProximaPergunta = () =>
		setQuestaoAtual((e) => (e + 1 > questoes.length ? e : e + 1));
	const irParaPerguntaAnterior = () =>
		setQuestaoAtual((e) => (e - 1 <= 0 ? 1 : e - 1));
	const questaoFoiRespondida = (index) => {
		return questoes[index].alternativa !== undefined;
	};
	const questoesForamRespondidas = () => {
		let todasRespondidas = true;
		for (let i = 0; i < questoes.length && todasRespondidas; i++) {
			todasRespondidas = questoes[i].alternativa !== undefined;
		}
		return todasRespondidas;
	};
	const ultimaQuestao = () => questaoAtual === questoes.length;
	const tratarFormularioParaSubmissao = () => {
		let alternativas = [];

		const num = crypto.randomUUID();

		questoes.forEach((questao) => {
			let resultadoAlternativa = {};
			resultadoAlternativa.chave = num;
			resultadoAlternativa.onboarding_perguntas_id = questao.id;
			resultadoAlternativa.alternativaIndex = parseInt(
				questao.alternativa
			);
			resultadoAlternativa.onboarding_perguntas_alternativas_id =
				questao.alternativas[questao.alternativa].value;
			resultadoAlternativa.classificacao =
				questao.alternativas[questao.alternativa]?.classificacao;
			alternativas.push(resultadoAlternativa);
		});

		onFinish(alternativas);
	};
	const alterarAlternativaQuestao = (novaAlternativa) => {
		let arr = [...questoes];
		arr[questaoAtual - 1].alternativa = novaAlternativa;
		setQuestoes(arr);
	};

	const NavegadorPaginasComponent = () => (
		<Box direction="row" sx={{ justifyContent: "center" }} spacing={1}>
			{listaQuestoes.map((_, index) => {
				let circleColor =
					index + 1 === questaoAtual
						? "#77DD77"
						: questaoFoiRespondida(index)
						? "#3065AC"
						: "#ccc";
				return (
					<Circle
						key={`circle-${crypto.randomUUID()}`}
						sx={{
							width: "24px",
							height: "24px",
							color: circleColor,
						}}
					/>
				);
			})}
		</Box>
	);

	return (
		<Box
			sx={{
				mx: {
					md: 20,
					lg: 50,
				},
				display: "flex",
				flexDirection: "column",
				justifyContent: {
					xs: "flex-start",
					sm: "center",
				},
			}}
		>
			{Array.isArray(questoes) &&
				questoes.map((questao, index) => {
					if (index + 1 !== questaoAtual) return null;
					const numeroQuestao = (index + 1)
						.toString()
						.padStart(2, "0");
					const enunciado = `${numeroQuestao}. ${questao.enunciado}`;
					return (
						<PesquisaPerfilQuestao
							key={`questao-${crypto.randomUUID()}`}
							enunciado={enunciado}
							alternativas={questao.alternativas}
							alternativaSelecionada={questao.alternativa}
							onChange={(_, value) =>
								alterarAlternativaQuestao(value)
							}
						/>
					);
				})}
			{isSmall ? (
				<Box
					sx={{
						mt: 2,
						display: "flex",
						flex: 1,
						flexDirection: "column",
						alignItems: "center",
						justifyContent: {
							xs: "flex-start",
							sm: "center",
						},
						gap: 2,
					}}
				>
					<NavegadorPaginasComponent />
					<Button
						sx={{
							width: "306px",
							textTransform: "none",
						}}
						variant="contained"
						endIcon={<ArrowForward />}
						onClick={
							ultimaQuestao()
								? onFinish !== undefined
									? () => tratarFormularioParaSubmissao()
									: () => {}
								: irParaProximaPergunta
						}
						disabled={
							ultimaQuestao() && !questoesForamRespondidas()
						}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 600,
								lineHeight: "150%",
								color: "#FFFFFF",
								fontFamily: "Inter",
							}}
						>
							{ultimaQuestao()
								? "Finalizar questionário"
								: "Próxima pergunta"}
						</Typography>
					</Button>
					<Button
						sx={{
							width: "306px",
							textTransform: "none",
							border: `2px solid ${
								questaoAtual <= 1 ? "#CCC" : "#558EE3"
							}`,
						}}
						startIcon={<ArrowBack />}
						onClick={irParaPerguntaAnterior}
						disabled={questaoAtual <= 1}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 600,
								lineHeight: "150%",
								color: questaoAtual <= 1 ? "#CCC" : "#3466B2",
								fontFamily: "Inter",
							}}
						>
							Voltar
						</Typography>
					</Button>
				</Box>
			) : (
				<Box
					sx={{
						mt: 2,
						display: "flex",
						alignItems: "center",
						justifyContent: {
							xs: "center",
							sm: "space-between",
						},
					}}
				>
					<Button
						sx={{
							width: "30%",
							textTransform: "none",
							border: `2px solid ${
								questaoAtual <= 1 ? "#CCC" : "#558EE3"
							}`,
						}}
						startIcon={<ArrowBack />}
						onClick={irParaPerguntaAnterior}
						disabled={questaoAtual <= 1}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 600,
								lineHeight: "150%",
								color: questaoAtual <= 1 ? "#CCC" : "#3466B2",
								fontFamily: "Inter",
							}}
						>
							Voltar
						</Typography>
					</Button>

					<NavegadorPaginasComponent />

					<Button
						sx={{
							width: "40%",
							textTransform: "none",
							backgroundColor: "#3466B2",
						}}
						variant="contained"
						endIcon={<ArrowForward />}
						onClick={
							ultimaQuestao()
								? onFinish !== undefined
									? () => tratarFormularioParaSubmissao()
									: () => {}
								: irParaProximaPergunta
						}
						disabled={
							ultimaQuestao() && !questoesForamRespondidas()
						}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 600,
								lineHeight: "150%",
								color: "#FFFFFF",
								fontFamily: "Inter",
							}}
						>
							{ultimaQuestao()
								? "Finalizar e conhecer meu perfil"
								: "Próxima pergunta"}
						</Typography>
					</Button>
				</Box>
			)}
		</Box>
	);
}

export default PesquisaPerfilQuestionario;
